import React from 'react';
import { NavLink } from 'react-router-dom';

var welding = require('./../../images/services/iconss/welding.png');
var grinding = require('./../../images/services/iconss/grinding.png');
var cylindricalGrinding = require('./../../images/services/iconss/cylindrical-grinding.png');
var honing = require('./../../images/services/iconss/honing.png');
var milling = require('./../../images/services/iconss/milling.png');
var turning = require('./../../images/services/iconss/turning.png');
var jobWorks = require('./../../images/services/iconss/jobWorks.png');
var oem = require('./../../images/services/iconss/oem.png');
var repairing = require('./../../images/services/iconss/repairing.png');

const services = [
  {
    count: 1,
    title: 'Welding',
    flaticon: welding,
    description:
      'Welding is a fabrication process that joins materials, usually metals.',
  },
  {
    count: 2,
    title: 'Surface Grinding',
    flaticon: grinding,
    description:
      'To provide precision ground surfaces, either to a critical size or for the surface finish.',
  },
  {
    count: 3,
    title: 'Cylindrical grinding',
    flaticon: cylindricalGrinding,
    description:
      'To provide precision ground surfaces, either to a critical size or for the surface finish.',
  },
  {
    count: 4,
    title: 'Honing',
    flaticon: honing,
    description:
      'Machining a surface by scrubbing an abrasive stone against it.',
  },
  {
    count: 5,
    title: 'Milling',
    flaticon: milling,
    description:
      'A process of using rotary cutters to remove material from a workpiece.',
  },
  {
    count: 6,
    title: 'Turning',
    flaticon: turning,
    description:
      'The general process of turning involves rotating a part while a single-point cutting tool is moved parallel to the axis of rotation.',
  },
  {
    count: 7,
    title: 'Job works',
    flaticon: jobWorks,
    description:
      'Undertaking any treatment or process by a person on goods belonging to another registered taxable person.',
  },
  {
    count: 8,
    title: 'OEM Components',
    flaticon: oem,
    description:
      'Provides components for the finished products of a value-added reseller (VAR).',
  },
  {
    count: 9,
    title: 'Repairing',
    flaticon: repairing,
    description:
      'The restoring of machinery or a building to its initial working condition without changing any of its features.',
  },
];

var img1 = require('./../../images/background/bg-5.png');
var img2 = require('./../../images/background/line.png');

class OurServices3 extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }
  render() {
    return (
      <>
        <div
          className='section-full p-t80 p-b50 bg-white mobile-page-padding'
          style={{ backgroundImage: 'url(' + img1 + ')' }}
        >
          <div className='container'>
            {/* TITLE START */}
            <div className='section-head'>
              <div className='mt-separator-outer separator-center'>
                <div className='mt-separator'>
                  <h2 className='text-uppercase sep-line-one '>
                    <span className='font-weight-300 text-primary'>All</span>{' '}
                    Services
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className='section-content'>
              <div className='row'>
                {services.map((item, index) => (
                  <div
                    key={index}
                    className='col-md-4 col-sm-6'
                    style={
                      {
                        // height: '350px'
                      }
                    }
                  >
                    <div className='mt-icon-box-wraper p-a30 center m-b30 box-shadow bg-white'>
                      <div
                        className='mt-icon-box-sm inline-icon text-primary  m-b20 radius bg-primary  scale-in-center bg-moving'
                        style={{ backgroundImage: 'url(' + img2 + ')' }}
                      >
                        <span className='icon-cell text-secondry'>
                          {/* <i className={item.flaticon} /> */}
                          <img
                            className={item.flaticon}
                            src={item.flaticon}
                            alt={item.flaticon}
                          />
                        </span>
                      </div>
                      <div className='icon-content'>
                        <h4 className='mt-tilte text-uppercase font-weight-600 m-b20'>
                          {item.title}
                        </h4>
                        <p
                          style={{
                            height: '5.5em',
                          }}
                        >
                          {item.description}
                        </p>
                        {/* <NavLink
                          to={'/services'}
                          className='site-button-link'
                          data-hover='Read More'
                        >
                          Read More{' '}
                          <i className='fa fa-angle-right arrow-animation' />
                        </NavLink> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OurServices3;
