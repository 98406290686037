import React from 'react';
import { NavLink } from 'react-router-dom';

class Navigation extends React.Component {

  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }

  render() {
    return (
      <>
        <div
          className={
            this.props.bgcolor !== ''
              ? `header-nav navbar-collapse collapse ${this.props.bgcolor}`
              : 'header-nav navbar-collapse collapse'
          }
        >
          <ul className='nav navbar-nav'>
            <li className={`nav-item`}>
              <NavLink
                to={'/'}
                end
                style={({ isActive, isPending }) => {
                  return {
                    color: isActive ? '#F5BF23' : 'inherit',
                  };
                }}
                className={({ isActive, isPending }) => {
                  return isActive ? 'active' : isPending ? 'pending' : '';
                }}
              >
                Home
              </NavLink>
            </li>
            <li className={`nav-item`}>
              <NavLink
                style={({ isActive, isPending }) => {
                  return {
                    color: isActive ? '#F5BF23' : 'inherit',
                  };
                }}
                className={({ isActive, isPending }) => {
                  return isActive ? 'active' : isPending ? 'pending' : '';
                }}
                to={'/about'}
              >
                About us
              </NavLink>
            </li>
            <li className={`nav-item`}>
              <NavLink
                style={({ isActive, isPending }) => {
                  return {
                    color: isActive ? '#F5BF23' : 'inherit',
                  };
                }}
                className={({ isActive, isPending }) => {
                  return isActive ? 'active' : isPending ? 'pending' : '';
                }}
                to={'/services'}
              >
                Services
              </NavLink>
            </li>
            <li className={`nav-item`}>
              <NavLink
                style={({ isActive, isPending }) => {
                  return {
                    color: isActive ? '#F5BF23' : 'inherit',
                  };
                }}
                className={({ isActive, isPending }) => {
                  return isActive ? 'active' : isPending ? 'pending' : '';
                }}
                to={'/projects'}
              >
                Projects
              </NavLink>
            </li>
            <li className={`nav-item`}>
              <NavLink
                style={({ isActive, isPending }) => {
                  return {
                    color: isActive ? '#F5BF23' : 'inherit',
                  };
                }}
                className={({ isActive, isPending }) => {
                  return isActive ? 'active' : isPending ? 'pending' : '';
                }}
                to={'/contactus'}
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default Navigation;
