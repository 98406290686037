import React from 'react';
import { NavLink } from 'react-router-dom';

class AboutCompany extends React.Component {
    render() {
        return (
          <>
            <div className='section-full p-t80 bg-white'>
              <div className='container'>
                <div className='section-content '>
                  <div className='m-service-containt text-black'>
                    <div className='row'>
                      <div className='col-md-5 col-sm-12'>
                        <div className='service-about-left'>
                          <div className='mt-media'>
                            <img
                              src={require('./../../images/banner/man.png')}
                              alt=''
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-7 col-sm-12'>
                        <div className='service-about-right m-b30'>
                          <h3 className='m-t0'>
                            We deliver with pride, ensuring top-notch quality.
                          </h3>
                          <p>
                            At our company, we deliver with pride, ensuring
                            top-notch quality in every aspect of our work. From
                            the initial design phase to the final
                            implementation, our team is dedicated to maintaining
                            the highest standards. We understand that our
                            reputation is built on the quality of our projects,
                            and we take that responsibility seriously. Every
                            detail is meticulously crafted, and every challenge
                            is met with innovative solutions, reflecting our
                            commitment to excellence. When you choose us, you
                            can trust that you are receiving the best in
                            mechanical engineering, backed by our unwavering
                            pride in what we do.
                          </p>
                          <div className='call-for-quote-outer'>
                            <div className='call-quote'>
                              <span>Call For a Quote:</span>
                              <h4>(+91)-9824062206</h4>
                            </div>
                            <div
                              className='call-estimate bg-dark'
                              data-toggle='modal'
                              data-target='#with-form'
                            >
                              <div className='site-button-secondry btn-effect'>
                                Online query Form
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
    }
};

export default AboutCompany;