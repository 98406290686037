import React from 'react';
import { NavLink } from 'react-router-dom';

var bnr1 = require('./../../images/background/bg-6.png');

class Specialization3 extends React.Component {
    render() {
        return (
          <>
            <div
              className='section-full p-t80 p-b50 bg-dark mobile-page-padding'
              style={{ backgroundImage: 'url(' + bnr1 + ')' }}
            >
              <div className='container'>
                {/* IMAGE CAROUSEL START */}
                <div className='section-content'>
                  <div className='section-head m-b50 text-white text-center'>
                    <h3 className='m-t0'>
                      Our character is molded by our mechanical engineering
                      processes.
                    </h3>
                    <p>
                      The intricate details of each mechanical component reflect
                      the precision and care we put into our work. Through every
                      challenge and innovation, our true nature as engineers is
                      continually shaped and refined.
                    </p>
                  </div>
                  <div className='row'>
                    <div className='col-md-4 col-sm-6 m-b30'>
                      <div className='image-effect'>
                        <img
                          src={require('../../images/services/Img-1.png')}
                          alt=''
                        />
                        {/* <div className='figcaption'>
                          <h4>Concept and Design</h4>
                          <p>
                            Est notare quam littera gothica, quam nunc putamus
                            parum claram.
                          </p>
                          <NavLink to='/project-detail'>
                            <i className='link-plus bg-primary' />
                          </NavLink>
                        </div> */}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 m-b30'>
                      <div className='image-effect'>
                        <img
                          src={require('../../images/services/Img-2.png')}
                          alt=''
                        />
                        {/* <div className='figcaption'>
                          <h4>Refurbishment</h4>
                          <p>
                            Est notare quam littera gothica, quam nunc putamus
                            parum claram.
                          </p>
                          <NavLink to='/project-detail'>
                            <i className='link-plus bg-primary' />
                          </NavLink>
                        </div> */}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-12 m-b30'>
                      <div className='image-effect'>
                        <img
                          src={require('../../images/services/Img-3.png')}
                          alt=''
                        />
                        {/* <div className='figcaption bg-dark'>
                          <h4>General Builder</h4>
                          <p>
                            Est notare quam littera gothica, quam nunc putamus
                            parum claram.
                          </p>
                          <NavLink to='/project-detail'>
                            <i className='link-plus bg-primary' />
                          </NavLink>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
    }
};

export default Specialization3;