import React from 'react';
import { NavLink } from 'react-router-dom';

const filters = [
  { label: '3D', filter: '.cat-1' },
  { label: 'JOB WORKS', filter: '.cat-2' },
];

const projects = [
  {
    image: require('./../../images/projects/3d/3D-1.png'),
    title: 'Chair Furniture',
    address: 'Muscat, Sultanate of Oman',
    width: 'col-md-4',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/3d/3D-2.png'),
    title: 'Furniture',
    address: 'North House',
    width: 'col-md-4',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/3d/3D-3.png'),
    title: 'Living room',
    address: 'Aqaba, Jordan',
    width: 'col-md-4',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/3d/3D-4.png'),
    title: 'Dream Workspace',
    address: 'Perth, Australia',
    width: 'col-md-4',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/3d/3D-5.png'),
    title: 'Decore',
    address: 'Aqaba, Jordan',
    width: 'col-md-4',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/3d/3D-6.png'),
    title: 'Modern Decoration',
    address: 'Muscat, Sultanate of Oman',
    width: 'col-md-4',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/3d/3D-7.png'),
    title: 'Modern Decoration',
    address: 'Muscat, Sultanate of Oman',
    width: 'col-md-4',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/3d/3D-8.png'),
    title: 'Modern Decoration',
    address: 'Muscat, Sultanate of Oman',
    width: 'col-md-4',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/3d/3D-9.png'),
    title: 'Modern Decoration',
    address: 'Muscat, Sultanate of Oman',
    width: 'col-md-4',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/job-works/JW-1.png'),
    title: 'Bathroom',
    address: 'North House',
    width: 'col-md-4',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/job-works/JW-2.png'),
    title: 'Lamp decoration',
    address: 'Aqaba, Jordan',
    width: 'col-md-4',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/job-works/JW-3.png'),
    title: 'Building house',
    address: 'Ultanate of Oman',
    width: 'col-md-4',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/job-works/JW-4.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-4',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/job-works/JW-5.png'),
    title: 'Bathroom',
    address: 'North House',
    width: 'col-md-4',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/job-works/JW-6.png'),
    title: 'Lamp decoration',
    address: 'Aqaba, Jordan',
    width: 'col-md-4',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/job-works/JW-7.png'),
    title: 'Building house',
    address: 'Ultanate of Oman',
    width: 'col-md-4',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/job-works/JW-8.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-4',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/job-works/JW-9.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-4',
    filter: 'cat-2',
  },
];

var img1 = require('./../../images/background/bg-machine.jpg');
var bnr1 = require('./../../images/background/bg-6.png');

class OurProject3 extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }
  render() {
    return (
      <>
        <div
          className='section-full mobile-page-padding p-t80 p-b30 bg-dark bg-repeat square_shape2 bg-moving'
          style={{ backgroundImage: 'url(' + bnr1 + ')' }}
        >
          <div className='container'>
            {/* TITLE START */}
            <div className='section-head'>
              <div className='mt-separator-outer separator-center'>
                <div className='mt-separator text-white'>
                  <h2 className='text-uppercase sep-line-one '>
                    <span className='font-weight-300 text-primary'>Our</span>{' '}
                    Project
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* PAGINATION START */}
            <div className='filter-wrap p-b15 center'>
              <ul className='masonry-filter link-style  text-uppercase white'>
                {/* <li className='active'>
                  <NavLink to={'#'} data-filter='*' data-hover='All'>
                    All
                  </NavLink>
                </li> 
                const filters = [
  { label: '3D', filter: '.cat-1' },
  { label: 'JOB WORKS', filter: '.cat-2' },
];
                */}
                {/* {filters.map((item, index) => (
                  <li key={index}>
                    <NavLink
                      to={'#'}
                      data-filter={item.filter}
                      data-hover={item.label}
                    >
                      {item.label}
                    </NavLink>
                  </li>
                ))} */}

                <li class='active'>
                  <NavLink to={'#'} data-filter={'.cat-1'} data-hover={'3D'}>
                    {'3D'}
                  </NavLink>
                </li>
                <li class=''>
                  <NavLink to={'#'} data-filter={'.cat-2'} data-hover={'3D'}>
                    {'JOB WORKS'}
                  </NavLink>
                </li>
              </ul>
            </div>
            {/* PAGINATION END */}
            {/* GALLERY CONTENT START */}
            <div className='portfolio-wrap mfp-gallery work-grid row clearfix'>
              {projects.map((item, index) => (
                <div
                  key={index}
                  className={`${item.filter} masonry-item ${item.width} col-sm-6 m-b30`}
                >
                  <div className='image-effect-three hover-shadow'>
                    {/* <div className='mt-thum-bx'> */}
                    <img src={item.image} alt={item.image} />
                    {/* </div> */}
                    {/* <div className="mt-info  p-t20 text-white">
                                            <h4 className="mt-tilte m-b10 m-t0">{item.title}</h4>
                                            <p className="m-b0">{item.address}</p>
                                        </div>
                                        <NavLink to="/project-detail"></NavLink> */}
                  </div>
                </div>
              ))}
            </div>
            {/* GALLERY CONTENT END */}
          </div>
        </div>
      </>
    );
  }
}

export default OurProject3;
