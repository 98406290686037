import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import ReactPlayer from 'react-player';
import OurProject2 from '../Elements/OurProject2';

var bnrimg = require('./../../images/banner/hero-banner-projects.png');

const filters = [
  { label: '3D', filter: '.cat-1' },
  { label: 'JOB WORKS', filter: '.cat-2' },
];

const projects = [
  {
    image: require('./../../images/projects/3d/3D-1.png'),
    title: 'Chair Furniture',
    address: 'Muscat, Sultanate of Oman',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/3d/3D-2.png'),
    title: 'Furniture',
    address: 'North House',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/3d/3D-3.png'),
    title: 'Living room',
    address: 'Aqaba, Jordan',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/3d/3D-4.png'),
    title: 'Dream Workspace',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/3d/3D-5.png'),
    title: 'Decore',
    address: 'Aqaba, Jordan',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/3d/3D-6.png'),
    title: 'Modern Decoration',
    address: 'Muscat, Sultanate of Oman',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/3d/3D-7.png'),
    title: 'Modern Decoration',
    address: 'Muscat, Sultanate of Oman',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/3d/3D-8.png'),
    title: 'Modern Decoration',
    address: 'Muscat, Sultanate of Oman',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/3d/3D-9.png'),
    title: 'Modern Decoration',
    address: 'Muscat, Sultanate of Oman',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/details/3d/3D-1.png'),
    title: 'Chair Furniture',
    address: 'Muscat, Sultanate of Oman',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/details/3d/3D-2.png'),
    title: 'Furniture',
    address: 'North House',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/details/3d/3D-3.png'),
    title: 'Living room',
    address: 'Aqaba, Jordan',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/details/3d/3D-4.png'),
    title: 'Dream Workspace',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/details/3d/3D-5.png'),
    title: 'Decore',
    address: 'Aqaba, Jordan',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/details/3d/3D-6.png'),
    title: 'Modern Decoration',
    address: 'Muscat, Sultanate of Oman',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/details/3d/3D-7.png'),
    title: 'Modern Decoration',
    address: 'Muscat, Sultanate of Oman',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/details/3d/3D-8.png'),
    title: 'Modern Decoration',
    address: 'Muscat, Sultanate of Oman',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/details/3d/3D-9.png'),
    title: 'Modern Decoration',
    address: 'Muscat, Sultanate of Oman',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/details/jobs/job1.png'),
    title: 'Bathroom',
    address: 'North House',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job2.png'),
    title: 'Lamp decoration',
    address: 'Aqaba, Jordan',
    width: 'col-md-6',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job3.png'),
    title: 'Building house',
    address: 'Ultanate of Oman',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job4.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job5.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job6.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job7.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job8.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job9.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job10.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/3d/3D-1.png'),
    title: 'Chair Furniture',
    address: 'Muscat, Sultanate of Oman',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/details/3d/3D-2.png'),
    title: 'Furniture',
    address: 'North House',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/details/3d/3D-3.png'),
    title: 'Living room',
    address: 'Aqaba, Jordan',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/details/3d/3D-4.png'),
    title: 'Dream Workspace',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/details/3d/3D-5.png'),
    title: 'Decore',
    address: 'Aqaba, Jordan',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/details/3d/3D-6.png'),
    title: 'Modern Decoration',
    address: 'Muscat, Sultanate of Oman',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/details/3d/3D-7.png'),
    title: 'Modern Decoration',
    address: 'Muscat, Sultanate of Oman',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/details/3d/3D-8.png'),
    title: 'Modern Decoration',
    address: 'Muscat, Sultanate of Oman',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/details/3d/3D-9.png'),
    title: 'Modern Decoration',
    address: 'Muscat, Sultanate of Oman',
    width: 'col-md-3',
    filter: 'cat-1',
  },
  {
    image: require('./../../images/projects/details/jobs/job1.png'),
    title: 'Bathroom',
    address: 'North House',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job2.png'),
    title: 'Lamp decoration',
    address: 'Aqaba, Jordan',
    width: 'col-md-6',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job3.png'),
    title: 'Building house',
    address: 'Ultanate of Oman',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job4.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job5.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job6.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job7.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job8.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job9.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job10.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job11.png'),
    title: 'Bathroom',
    address: 'North House',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job12.png'),
    title: 'Lamp decoration',
    address: 'Aqaba, Jordan',
    width: 'col-md-6',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job13.png'),
    title: 'Building house',
    address: 'Ultanate of Oman',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job14.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job15.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job16.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job17.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job18.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job19.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job20.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job21.png'),
    title: 'Bathroom',
    address: 'North House',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job22.png'),
    title: 'Lamp decoration',
    address: 'Aqaba, Jordan',
    width: 'col-md-6',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job23.png'),
    title: 'Building house',
    address: 'Ultanate of Oman',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job24.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job25.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job26.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job27.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job28.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job29.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job30.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job31.png'),
    title: 'Bathroom',
    address: 'North House',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job32.png'),
    title: 'Lamp decoration',
    address: 'Aqaba, Jordan',
    width: 'col-md-6',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job33.png'),
    title: 'Building house',
    address: 'Ultanate of Oman',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job34.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job35.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },
  {
    image: require('./../../images/projects/details/jobs/job36.png'),
    title: 'Spa residence',
    address: 'Perth, Australia',
    width: 'col-md-3',
    filter: 'cat-2',
  },

];


const ProjectGrid = () =>  {
    const [playing, setPlaying] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  useEffect(() => {
    console.log("here1");
    const handleModalShow = (e) => {
      console.log("here2");
      console.log("data: ",e);
      const videoUrl = e.relatedTarget.getAttribute('data-video-url');
      console.log('videoUrl: ', videoUrl);
      setCurrentVideo(videoUrl);
      setPlaying(true);
    };
    
    const handleModalHide = () => {
      console.log("here hide");
      setPlaying(false);
      setCurrentVideo(null);
    };

    document
      .querySelectorAll('.modal')
      .forEach((modal) => {
        modal.addEventListener('show.bs.modal', handleModalShow);
        modal.addEventListener('hide.bs.modal', handleModalHide);
      });

    return () => {
      document
        .querySelectorAll('.modal')
        .forEach((modal) => {
          modal.removeEventListener('show.bs.modal', handleModalShow);
          modal.removeEventListener('hide.bs.modal', handleModalHide);
        });
    };
  }, []);
  // componentDidMount() {
  //   function loadScript(src) {
  //     return new Promise(function (resolve, reject) {
  //       var script = document.createElement('script');
  //       script.src = src;
  //       script.addEventListener('load', function () {
  //         resolve();
  //       });
  //       script.addEventListener('error', function (e) {
  //         reject(e);
  //       });
  //       document.body.appendChild(script);
  //       document.body.removeChild(script);
  //     });
  //   }

  //   loadScript('./assets/js/masonary.js');
  // }

  // render() {
    return (
      <>
        <Header />
        <div className='page-content'>
          <Banner
            title='Always dedicated and devoted'
            pagename='Project Grid'
            bgimage={bnrimg}
          />

          {/* SECTION CONTENT START */}
          <div className='section-full p-tb80 inner-page-padding'>
            <div className='container'>
              {/* PAGINATION START */}
              <div className='filter-wrap p-b30 text-center'>
                <ul className='filter-navigation inline-navigation masonry-filter link-style  text-uppercase'>
                  <li className='active'>
                    <NavLink to={'#'} data-filter='*' data-hover='All'>
                      All
                    </NavLink>
                  </li>
                  {filters.map((item, index) => (
                    <li key={index}>
                      <NavLink
                        to={'#'}
                        data-filter={item.filter}
                        data-hover={item.label}
                      >
                        {item.label}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              {/* PAGINATION END */}
              {/* GALLERY CONTENT START */}
              <div className='portfolio-wrap mfp-gallery work-grid row clearfix'>
                {projects.map((item, index) => (
                  <div
                    key={index}
                    className={`${item.filter} masonry-item col-md-4 col-sm-6 m-b30`}
                  >
                    <div className='image-effect-three hover-shadow'>
                      <img src={item.image} alt={item.image} />
                      {/* <div className="figcaption">
                                                <h4 className="mt-tilte">{item.title}</h4>
                                                <p>{item.description}</p>
                                                <NavLink to="/project-detail" className="read-more site-button btn-effect">Read More</NavLink>
                                                <a className="mfp-link" href={item.image}>
                                                    <i className="fa fa-arrows-alt" />
                                                </a>
                                            </div> */}
                    </div>
                  </div>
                ))}
              </div>
              {/* GALLERY CONTENT END */}
              {/* <div className="m-t50 text-center">
                                <NavLink to={"#"} className="site-button btn-effect">View More</NavLink>
                            </div> */}

       
            </div>
          </div>
          {/* SECTION CONTENT END  */}
        </div>

        {/* Modal to play video */}
        {/* <div className='modal fade' id='modalJob1' role='dialog'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <ReactPlayer
                url={currentVideo}
                playing={playing}
                width='100%'
                height='100%'
                controls
              />
            </div>
          </div>
        </div> */}

        {/* <div className='modal fade' id='modalJob1' role='dialog'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <ReactPlayer
                url={currentVideo}
                playing={playing}
                width='100%'
                height='100%'
                controls
              />
            </div>
          </div>
        </div> */}
        {/* <div className='modal fade' id='modalJob2' role='dialog'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <ReactPlayer
                url={currentVideo}
                playing={playing}
                width='100%'
                height='100%'
                controls
              />
            </div>
          </div>
        </div>
        <div className='modal fade' id='modalJob3' role='dialog'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <ReactPlayer
                url={currentVideo}
                playing={playing}
                width='100%'
                height='100%'
                controls
              />
            </div>
          </div>
        </div> */}
        {/* <div className='modal fade' id='modalJob4' role='dialog'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <ReactPlayer url='https://api.dkengineeringwork.com/videos/job4' />
            </div>
          </div>
        </div>
        <div className='modal fade' id='modalJob5' role='dialog'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <ReactPlayer url='https://api.dkengineeringwork.com/videos/job5' />
            </div>
          </div>
        </div>
        <div className='modal fade' id='modalJob6' role='dialog'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <ReactPlayer url='https://api.dkengineeringwork.com/videos/job6' />
            </div>
          </div>
        </div>
        <div className='modal fade' id='modalJob7' role='dialog'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <ReactPlayer url='https://api.dkengineeringwork.com/videos/job7' />
            </div>
          </div>
        </div>
        <div className='modal fade' id='modalJob8' role='dialog'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <ReactPlayer url='https://api.dkengineeringwork.com/videos/job8' />
            </div>
          </div>
        </div>
        <div className='modal fade' id='modalJob9' role='dialog'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <ReactPlayer url='https://api.dkengineeringwork.com/videos/job9' />
            </div>
          </div>
        </div>
        <div className='modal fade' id='modalJob10' role='dialog'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <ReactPlayer url='https://api.dkengineeringwork.com/videos/job10' />
            </div>
          </div>
        </div> */}
        <OurProject2 />
        <Footer />
      </>
    );
  }
// }

export default ProjectGrid;
