import React from 'react';
import ReactPlayer from 'react-player';
import { NavLink } from 'react-router-dom';

const videos = [
  {
    thumb: require('./../../images/projects/portrait/pic4.jpg'),
    image: require('./../../images/projects/portrait/pic4.jpg'),
    name: '#modalJob1',
    title: 'Life style building',
    description: 'Engineering your dreams with us.',
    filter: 'cat-1',
    width: '360px',
    height: '632px',
    videoUrl: 'https://api.dkengineeringwork.com/videos/job1',
  },
  {
    thumb: require('./../../images/projects/portrait/pic4.jpg'),
    image: require('./../../images/projects/portrait/pic4.jpg'),
    name: '#modalJob2',
    title: 'Life style building',
    description: 'Engineering your dreams with us.',
    filter: 'cat-1',
    width: '360px',
    height: '632px',
    videoUrl: 'https://api.dkengineeringwork.com/videos/job2',
  },
  // {
  //   thumb: require('./../../images/projects/pic-1.jpg'),
  //   image: require('./../../images/projects/pic-1.jpg'),
  //   name: '#modalJob3',
  //   title: 'Central City Building',
  //   description: 'Engineering your dreams with us.',
  //   filter: 'cat-2',
  //   videoUrl: 'https://api.dkengineeringwork.com/videos/job3',
  // },
  {
    thumb: require('./../../images/projects/portrait/pic4.jpg'),
    image: require('./../../images/projects/portrait/pic4.jpg'),
    name: '#modalJob1',
    title: 'Life style building',
    description: 'Engineering your dreams with us.',
    filter: 'cat-2',
    videoUrl: 'https://api.dkengineeringwork.com/videos/job4',
  },
  {
    thumb: require('./../../images/projects/portrait/pic4.jpg'),
    image: require('./../../images/projects/portrait/pic4.jpg'),
    name: '#modalJob2',
    title: 'Life style building',
    description: 'Engineering your dreams with us.',
    filter: 'cat-2',
    videoUrl: 'https://api.dkengineeringwork.com/videos/job5',
  },
  {
    thumb: require('./../../images/projects/pic-1.jpg'),
    image: require('./../../images/projects/pic-1.jpg'),
    name: '#modalJob3',
    title: 'Central City Building',
    description: 'Engineering your dreams with us.',
    filter: 'cat-2',
    videoUrl: 'https://api.dkengineeringwork.com/videos/job6',
  },
  {
    thumb: require('./../../images/projects/pic-1.jpg'),
    image: require('./../../images/projects/pic-1.jpg'),
    name: '#modalJob3',
    title: 'Central City Building',
    description: 'Engineering your dreams with us.',
    filter: 'cat-2',
    videoUrl: 'https://api.dkengineeringwork.com/videos/job7',
  },
  {
    thumb: require('./../../images/projects/pic-1.jpg'),
    image: require('./../../images/projects/pic-1.jpg'),
    name: '#modalJob3',
    title: 'Central City Building',
    description: 'Engineering your dreams with us.',
    filter: 'cat-2',
    videoUrl: 'https://api.dkengineeringwork.com/videos/job8',
  },
  {
    thumb: require('./../../images/projects/pic-1.jpg'),
    image: require('./../../images/projects/pic-1.jpg'),
    name: '#modalJob3',
    title: 'Central City Building',
    description: 'Engineering your dreams with us.',
    filter: 'cat-2',
    videoUrl: 'https://api.dkengineeringwork.com/videos/job9',
  },
//   {
//     thumb: require('./../../images/projects/pic-1.jpg'),
//     image: require('./../../images/projects/pic-1.jpg'),
//     name: '#modalJob3',
//     title: 'Central City Building',
//     description: 'Engineering your dreams with us.',
//     filter: 'cat-2',
//     videoUrl: 'https://api.dkengineeringwork.com/videos/job10',
//   },
];
var bnr1 = require('./../../images/background/bg-6.png');
class OurProject2 extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }

  render() {
    return (
      <>
        <div
          className='section-full mobile-page-padding p-t80 p-b30 bg-dark bg-repeat square_shape2 bg-moving'
          style={{ backgroundImage: 'url(' + bnr1 + ')' }}
        >
          <div className='container'>
            {/* TITLE START */}
            <div className='section-head'>
              <div className='mt-separator-outer separator-center'>
                <div className='mt-separator'>
                  <h2 className='text-white text-uppercase sep-line-one '>
                    <span className='font-weight-300 text-primary'>Our</span>{' '}
                    Videos
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className='section-content'>
              <div
                className=''
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {videos.map((item, index) => (
                  <div key={index} className={`  m-b30`}>
                    <div className=''>
                      {/* <img src={item.image} alt='' /> */}
                      <ReactPlayer
                        url={item.videoUrl}
                        // width={'100%'}
                        // height={'100%'}
                        // width={item.width ? item.width : '632px'}
                        // height={item.height ? item.height : '360px'}
                        controls
                      />
                      {/* <button
                      type='button'
                      className='play-now'
                      data-toggle='modal'
                      data-target={item.name}
                      data-video-url={item.videoUrl}
                    >
                      <i className='icon fa fa-play' />
                      <span className='ripple' />
                    </button> */}
                    </div>
                    {/* <div className='figcaption'>
                      <h4>{item.title}</h4>
                      <p>{item.category}</p>
                      <NavLink to='/project-detail'>
                        <i className='link-plus bg-primary' />
                      </NavLink>
                    </div> */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OurProject2;
