import React from 'react';
import Navigation from '../Common/Navigation';
import { NavLink } from 'react-router-dom';

var bnr = require('./../../images/background/bg-5.png');

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { logo: require('./../../images/logo-dark-1.png') };
  }

  state = { isSearchActive: false, isQuoteActive: false };

  handleSearchToggle = () => {
    this.setState({ isSearchActive: !this.state.isSearchActive });
  };

  handleQuoteToggle = () => {
    this.setState({ isQuoteActive: !this.state.isQuoteActive });
  };

  componentDidMount() {
    const handleScroll = () => {
      const offset = window.scrollY;

      const stickyheader = document.querySelector('.sticky-header ');

      if (offset >= 100) {
        stickyheader.classList.add('is-fixed');
        stickyheader.classList.add('color-fill');
      } else {
        stickyheader.classList.remove('is-fixed');
        stickyheader.classList.remove('color-fill');
      }
    };

    window.addEventListener('scroll', handleScroll);

    window.updateTopMostParent = (logopath) => {
      this.setState({ logo: logopath });
    };
  }

  render() {
    const isSearchActive = this.state.isSearchActive;
    const isQuoteActive = this.state.isQuoteActive;
    
    const onSubmit = async (event) => {
      event.preventDefault();
      console.log('Sending....');
      console.log('Data ', event.target);
      const formData = new FormData(event.target);

      formData.append('access_key', '1b58c83d-6678-4bde-ba7b-5cdccbd0220a');

      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      if (data.success) {
        console.log('Form Submitted Successfully');
        event.target.reset();
        navigator.NavLink.to = "/"
      } else {
        console.log('Error', data);
        console.log(data.message);
      }
    };
    return (
      <>
        <header className='site-header header-style-1'>
          <div className='top-bar bg-gray'>
            <div className='container'>
              <div className='row'>
                <div className='mt-topbar-left clearfix'>
                  <ul className='list-unstyled e-p-bx pull-right'>
                    <li>
                      <i className='fa fa-envelope' /> dkenggvapi@gmail.com
                    </li>
                    <li>
                      <i className='fa fa-phone' />
                      (+91)-9824062206
                    </li>
                    <li>
                      <i className='fa fa-clock-o' />
                      Mon-Sat 08:00 to 20:00 IST
                    </li>
                  </ul>
                </div>
                <div className='mt-topbar-right clearfix'>
                  {/* TRIGGER THE MODAL WITH A BUTTON */}
                  <div className='mt-topbar-right clearfix'>
                    <div
                      className='appint-btn'
                      data-toggle='modal'
                      data-target='#with-form'
                    >
                      <NavLink to={'#'} className='site-button'>
                        Online Query Form
                      </NavLink>
                    </div>
                  </div>
                  {/* MODAL */}
                  <div
                    id='with-form'
                    className='modal fade'
                    role='dialog'
                    data-backdrop='false'
                  >
                    <div className='modal-dialog'>
                      {/* Modal content*/}
                      <div className='modal-content'>
                        <div className='modal-header bg-secondry'>
                          <button
                            type='button'
                            className='close'
                            data-dismiss='modal'
                          >
                            ×
                          </button>
                          <h4 className='modal-title text-white'>
                            Get In Touch
                          </h4>
                        </div>
                        <div className='modal-body'>
                          <form
                            // id='demo-form'
                            className='form-horizontal mb-lg'
                            onSubmit={onSubmit}
                          >
                            <input
                              type='hidden'
                              name='subject'
                              value={`New message from DK_ENGINEERING_WORK`}
                            />
                            <input
                              type='hidden'
                              name='access_key'
                              value='1b58c83d-6678-4bde-ba7b-5cdccbd0220a'
                            />
                            <div className='form-group mt-lg'>
                              <label className='col-sm-3 control-label'>
                                Name
                              </label>
                              <div className='col-sm-9'>
                                <input
                                  name='name'
                                  className='form-control'
                                  placeholder='Type your name...'
                                  required
                                  type='text'
                                />
                              </div>
                            </div>
                            <div className='form-group'>
                              <label className='col-sm-3 control-label'>
                                Email
                              </label>
                              <div className='col-sm-9'>
                                <input
                                  name='email'
                                  className='form-control'
                                  placeholder='Type your email...'
                                  required
                                  type='email'
                                />
                              </div>
                            </div>
                            <div className='form-group'>
                              <label className='col-sm-3 control-label'>
                                Message
                              </label>
                              <div className='col-sm-9'>
                                <textarea
                                  rows={5}
                                  className='form-control'
                                  placeholder='Type your message...'
                                  required
                                  defaultValue={''}
                                />
                              </div>
                            </div>
                            <div className='form-group'>
                              <label
                                className='col-sm-3 control-label'
                                style={{
                                  paddingTop: '20px',
                                }}
                              >
                                Category
                              </label>
                              <div className='col-sm-9'>
                                <input
                                  type='checkbox'
                                  id='manufacturing'
                                  name='manufacturing'
                                  value='Manufacturing'
                                />
                                <label for='manufacturing'>Manufacturing</label>
                                <input
                                  type='checkbox'
                                  id='designDevelopment'
                                  name='designDevelopment'
                                  value='Design & Development'
                                />
                                <label for='designDevelopment'>
                                  Design & Development
                                </label>
                                <input
                                  type='checkbox'
                                  id='maintenance'
                                  name='maintenance'
                                  value='Maintenance'
                                />
                                <label for='maintenance'>Maintenance</label>
                                <input
                                  type='checkbox'
                                  id='repairing'
                                  name='repairing'
                                  value='Repairing'
                                />
                                <label for='repairing'>Repairing</label>
                              </div>
                            </div>
                            <div className='text-right'>
                              <button
                                type='button'
                                className='site-button-secondry btn-effect text-uppercase button-sm letter-spacing-2 m-r15'
                                data-dismiss='modal'
                              >
                                Close
                              </button>
                              <button
                                name='submit'
                                type='submit'
                                className='site-button right btn-effect text-uppercase button-sm letter-spacing-2'
                              >
                                submit
                              </button>
                            </div>
                          </form>
                        </div>
                        {/* <div className='modal-footer'>
                          <button
                            type='button'
                            className='site-button-secondry btn-effect text-uppercase button-sm letter-spacing-2 m-r15'
                            data-dismiss='modal'
                          >
                            Close
                          </button>
                          <button
                            name='submit'
                            type='submit'
                            // onClick={onSubmit}
                            className='site-button btn-effect text-uppercase button-sm letter-spacing-2'
                          >
                            submit
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='sticky-header main-bar-wraper'>
            <div
              className='main-bar bg-white'
              style={{
                paddingBottom: '0px',
                paddingTop: '0px',
              }}
            >
              <div className='container'>
                <div className='logo-header'>
                  <div className='logo-header-inner logo-header-one'>
                    <NavLink to={'/'}>
                      <img src={this.state.logo} alt='DK' />
                    </NavLink>
                  </div>
                </div>
                {/* NAV Toggle Button */}
                <button
                  data-target='.header-nav'
                  data-toggle='collapse'
                  type='button'
                  className='navbar-toggle collapsed'
                >
                  <span className='sr-only'>Toggle navigation</span>
                  <span className='icon-bar' />
                  <span className='icon-bar' />
                  <span className='icon-bar' />
                </button>
                {/* ETRA Nav */}
                <div className='extra-nav'>
                  {/* <div className="extra-cell">
                                        <NavLink to={"#"} onClick={this.handleSearchToggle}> 
                                            <i className="fa fa-search" />
                                        </NavLink>
                                    </div> */}
                  <div className='extra-cell'>
                    <NavLink
                      to={'#'}
                      className='contact-slide-show'
                      onClick={this.handleQuoteToggle}
                    >
                      <i className='fa fa-angle-left arrow-animation' />
                    </NavLink>
                  </div>
                </div>
                {/* ETRA Nav */}
                {/* Contact Nav */}
                <div
                  className='contact-slide-hide '
                  style={{
                    backgroundImage: 'url(' + bnr + ')',
                    right: isQuoteActive ? '0px' : '-500px',
                  }}
                >
                  <div className='contact-nav'>
                    <NavLink
                      to={'#'}
                      className='contact_close'
                      onClick={this.handleQuoteToggle}
                    >
                      ×
                    </NavLink>
                    <div className='contact-nav-form p-a30'>
                      <div className='contact-info   m-b30'>
                        <div className='mt-icon-box-wraper center p-b30'>
                          <div className='icon-xs m-b20 scale-in-center'>
                            <i className='fa fa-phone' />
                          </div>
                          <div className='icon-content'>
                            <h5 className='m-t0 font-weight-500'>
                              Phone number
                            </h5>
                            <p>(+91)-9924116602</p>
                          </div>
                        </div>
                        <div className='mt-icon-box-wraper center p-b30'>
                          <div className='icon-xs m-b20 scale-in-center'>
                            <i className='fa fa-envelope' />
                          </div>
                          <div className='icon-content'>
                            <h5 className='m-t0 font-weight-500'>
                              Email address
                            </h5>
                            <p>dkenggvapi@gmail.com</p>
                          </div>
                        </div>
                        <div className='mt-icon-box-wraper center p-b30'>
                          <div className='icon-xs m-b20 scale-in-center'>
                            <i className='fa fa-map-marker' />
                          </div>
                          <div className='icon-content'>
                            <h5 className='m-t0 font-weight-500'>
                              Address info
                            </h5>
                            <p>
                              PL. NO. C1/324/3, 40 SHED, AREA G.I.D.C., VAPI,
                              VALSAD, GUJARAT.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <div className='full-social-bg'>
                        <ul>
                          <li>
                            <NavLink to={'#'} className='facebook'>
                              <i className='fa fa-facebook' />
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={'#'} className='google'>
                              <i className='fa fa-google' />
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={'#'} className='instagram'>
                              <i className='fa fa-instagram' />
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={'#'} className='tumblr'>
                              <i className='fa fa-tumblr' />
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={'#'} className='twitter'>
                              <i className='fa fa-twitter' />
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={'#'} className='youtube'>
                              <i className='fa fa-youtube' />
                            </NavLink>
                          </li>
                        </ul>
                      </div> */}
                      <div className='text-center'>
                        <h4 className='font-weight-600'>
                          © 2024 D.K. Engineering Works.
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                {/* SITE Search */}
                {/* <div id="search" className={isSearchActive ? "open" : null}>
                                    <span className="close" onClick={this.handleSearchToggle} />
                                    <form role="search" id="searchform" action="/search" method="get" className="radius-xl">
                                        <div className="input-group">
                                            <input defaultValue="" name="q" type="search" placeholder="Type to search" />
                                            <span className="input-group-btn"><button type="button" className="search-btn"><i className="fa fa-search arrow-animation" /></button></span>
                                        </div>
                                    </form>`
                                </div> */}
                {/* MAIN Vav */}
                <Navigation />
              </div>
              <div
                style={{
                  background: '#FFDF84',
                  padding: '9px',
                  color: ' #000',
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '900',
                  lineHeight: '18px' /* 128.571% */,
                  letterSpacing: '6px',
                  textTransform: 'uppercase',
                  textAlign: 'center',
                }}
              >
                one stop engineering solution for design, development and
                manufacturing
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default Header;
