import React from 'react';

var img1 = require('./../../images/background/bg-6.png');
var img2 = require('./../../images/background/line.png');

class AboutSummary extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }
  render() {
    return (
      <>
        <div
          className='section-full p-t80 p-b80 bg-dark bg-repeat square_shape2 inner-page-padding'
          style={{ backgroundImage: 'url(' + img1 + ')' }}
        >
          <div className='container'>
            {/* TITLE START */}
            <div className='section-head'>
              <div className='mt-separator-outer separator-left'>
                <div className='mt-separator text-white'>
                  <h2 className='text-uppercase sep-line-one '>
                    <span className='font-weight-300 text-primary'>Our</span>{' '}
                    Work Process
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className='section-content our-story'>
              <div
                className='row'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div className='col-md-4 col-sm-6'>
                  <div className='our-story-pic-block'>
                    <div className='mt-media our-story-pic'>
                      <img
                        src={require('./../../images/about/primary.png')}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <div className='col-md-8 col-sm-6'>
                  <div
                    className='mt-box our-story-detail bg-moving bg-cover'
                    style={{
                      backgroundImage: 'url(' + img2 + ')',
                      backgroundColor: '#111111bf',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                      }}
                    >
                      <div
                        style={{
                          WebkitTextStrokeWidth: '1px',
                          WebkitTextStrokeColor: '#FFC54F',
                          color: '#111',
                          fontFamily: 'Poppins',
                          fontSize: '100px',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          lineHeight: '100px', // 100%
                          letterSpacing: '-3px',
                          width: '150px',
                        }}
                      >
                        01
                      </div>
                      <div>
                        <h4 className='m-b20'>Order Handling</h4>
                        <p
                          style={{
                            color: '#777',
                          }}
                        >
                          Efficient management of customer orders and
                          procurement.
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                      }}
                    >
                      <div
                        style={{
                          WebkitTextStrokeWidth: '1px',
                          WebkitTextStrokeColor: '#FFC54F',
                          color: '#111',
                          fontFamily: 'Poppins',
                          fontSize: '100px',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          lineHeight: '100px', // 100%
                          letterSpacing: '-3px',
                          width: '150px',
                        }}
                      >
                        02
                      </div>
                      <div>
                        <h4 className='m-b20'>Manufacturing</h4>
                        <p
                          style={{
                            color: '#777',
                          }}
                        >
                          Precision fabrication and assembly of mechanical
                          components.
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                      }}
                    >
                      <div
                        style={{
                          WebkitTextStrokeWidth: '1px',
                          WebkitTextStrokeColor: '#FFC54F',
                          color: '#111',
                          fontFamily: 'Poppins',
                          fontSize: '100px',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          lineHeight: '100px', // 100%
                          letterSpacing: '-3px',
                          width: '150px',
                        }}
                      >
                        03
                      </div>
                      <div>
                        <h4 className='m-b20'>Inspection & Delivery</h4>
                        <p
                          style={{
                            color: '#777',
                          }}
                        >
                          Quality control and timely dispatch of finished
                          products.
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                      }}
                    >
                      <div
                        style={{
                          WebkitTextStrokeWidth: '1px',
                          WebkitTextStrokeColor: '#FFC54F',
                          color: '#111',
                          fontFamily: 'Poppins',
                          fontSize: '100px',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          lineHeight: '100px', // 100%
                          letterSpacing: '-3px',
                          width: '150px',
                        }}
                      >
                        04
                      </div>
                      <div>
                        <h4 className='m-b20'>Review & Improvement</h4>
                        <p
                          style={{
                            color: '#777',
                          }}
                        >
                          Continuous enhancement through feedback and
                          innovation.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AboutSummary;
