import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import GoogleMapReact from 'google-map-react';
import { NavLink } from 'react-router-dom';

var bnrimg = require('./../../images/banner/hero-banner-about.png');
var mapImage = require('./../../images/gmap.jpg');

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class ContactUs extends React.Component {
  render() {
    // const defaultProps = {
    //   center: {
    //     lat: 34.07328,
    //     lng: -118.25141,
    //   },
    //   zoom: 12,
    // };
    const submitForm = (e) => {
      e.preventDefault();
      console.log('Form submitted');
    };

    const onSubmit = async (event) => {
      event.preventDefault();
      console.log('Sending....');
      const formData = new FormData(event.target);

      formData.append('access_key', '1b58c83d-6678-4bde-ba7b-5cdccbd0220a');

      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      if (data.success) {
        console.log('Form Submitted Successfully');
        event.target.reset();
      } else {
        console.log('Error', data);
        console.log(data.message);
      }
    };
    return (
      <>
        <Header />
        <div className='page-content'>
          <Banner
            title='Putting a plan to action, to assure your satisfaction!'
            pagename='Contact Us'
            bgimage={bnrimg}
          />

          {/* SECTION CONTENTG START */}
          <div className='section-full p-tb80 inner-page-padding'>
            {/* LOCATION BLOCK*/}
            <div className='container'>
              {/* GOOGLE MAP & CONTACT FORM */}
              <div className='section-content'>
                {/* CONTACT FORM*/}
                <div className='row'>
                  <div className='col-md-8 col-sm-6'>
                    <form
                      className='contact-form cons-contact-form'
                      onSubmit={onSubmit}
                    >
                      <div className='contact-one m-b30'>
                        {/* TITLE START */}
                        <div className='section-head'>
                          <div className='mt-separator-outer separator-left'>
                            <div className='mt-separator'>
                              <h2 className='text-uppercase sep-line-one '>
                                <span className='font-weight-300 text-primary'>
                                  Get
                                </span>{' '}
                                In touch
                              </h2>
                            </div>
                          </div>
                        </div>
                        {/* TITLE END */}
                        <input
                          type='hidden'
                          name='access_key'
                          value='1b58c83d-6678-4bde-ba7b-5cdccbd0220a'
                        />
                        <div className='form-group'>
                          <input
                            name='name'
                            type='text'
                            required
                            className='form-control'
                            placeholder='Name'
                          />
                        </div>
                        <div className='form-group'>
                          <input
                            name='email'
                            type='text'
                            className='form-control'
                            required
                            placeholder='Email'
                          />
                        </div>
                        <div className='form-group'>
                          <textarea
                            name='message'
                            rows={4}
                            className='form-control '
                            required
                            placeholder='Message'
                            defaultValue={''}
                          />
                        </div>
                        <div
                          className='form-group'
                          style={{
                            border: '1px solid #ddd',
                            paddingLeft: '10px',
                          }}
                        >
                          <input
                            type='checkbox'
                            id='manufacturing'
                            name='manufacturing'
                            value='Manufacturing'
                          />
                          <label for='manufacturing'>Manufacturing</label>
                          <input
                            type='checkbox'
                            id='designDevelopment'
                            name='designDevelopment'
                            value='Design & Development'
                          />
                          <label for='designDevelopment'>
                            Design & Development
                          </label>
                          <input
                            type='checkbox'
                            id='maintenance'
                            name='maintenance'
                            value='Maintenance'
                          />
                          <label for='maintenance'>Maintenance</label>
                          <input
                            type='checkbox'
                            id='repairing'
                            name='repairing'
                            value='Repairing'
                          />
                          <label for='repairing'>Repairing</label>
                        </div>
                        <div
                          className='text-left'
                          style={{
                            paddingTop: '10px',
                          }}
                        >
                          <button
                            name='submit'
                            type='submit'
                            // value='Submit'
                            className='site-button right btn-effect'
                            // onClick={submitForm}
                          >
                            submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className='col-md-4 col-sm-6'>
                    <div className='contact-info m-b30'>
                      {/* TITLE START */}
                      <div className='section-head'>
                        <div className='mt-separator-outer separator-left'>
                          <div className='mt-separator'>
                            <h2 className='text-uppercase sep-line-one '>
                              <span className='font-weight-300 text-primary'>
                                Contact
                              </span>{' '}
                              Info
                            </h2>
                          </div>
                        </div>
                      </div>
                      {/* TITLE END */}
                      <div className='bg-dark p-a20 text-white'>
                        <div className='mt-icon-box-wraper left p-b40'>
                          <div className='icon-xs'>
                            <i className='fa fa-phone' />
                          </div>
                          <div className='icon-content'>
                            <h5 className='m-t0 font-weight-500'>
                              Phone number
                            </h5>
                            <p>(+91)-9824062206</p>
                          </div>
                        </div>
                        <div className='mt-icon-box-wraper left p-b40'>
                          <div className='icon-xs'>
                            <i className='fa fa-envelope' />
                          </div>
                          <div className='icon-content'>
                            <h5 className='m-t0 font-weight-500'>
                              Email address
                            </h5>
                            <p>dkenggvapi@gmail.com</p>
                          </div>
                        </div>
                        <div className='mt-icon-box-wraper left'>
                          <div className='icon-xs'>
                            <i className='fa fa-map-marker' />
                          </div>
                          <div className='icon-content'>
                            <h5 className='m-t0 font-weight-500'>
                              Address info
                            </h5>
                            <p>
                              PL. NO. C1/324/3, 40 SHED AREA, NEAR N.R. JASANI,
                              G.I.D.C., VAPI, VALSAD, GUJARAT.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='gmap-outline'>
                <a
                  href='https://maps.app.goo.gl/wHdfdAadF978F7B96?g_st=iw'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <div style={{ height: '400px', width: '100%' }}>
                    <img src={mapImage} alt='Map is lodding..' />
                    {/*  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: 'AIzaSyAfY1DRbspf6E3jYUso-PeI_tdfRXA59i0',
                    }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                  >
                    <AnyReactComponent
                      lat={34.07328}
                      lng={-118.25141}
                      text={<i className='fa fa-map-marker' />}
                    />
                  </GoogleMapReact>*/}
                  </div>
                </a>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>

        <Footer />
      </>
    );
  }
}

export default ContactUs;
