import React from 'react';
import { NavLink } from 'react-router-dom';

// var img1 = require('./../../images/background/bg-4.png');
var img2 = require('./../../images/services/exp-img.png');

class OurValue3 extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }

  render() {
    return (
      <>
        <div
          className='section-full p-t80 p-b50 bg-gray bg-no-repeat bg-bottom-right mobile-page-padding'
          // style={{ backgroundImage: 'url(' + img1 + ')' }}
        >
          <div className='container'>
            <div className='section-content '>
              <div
                className='row'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div className='col-md-7 col-sm-6  m-b30'>
                  <div className='brochur bg-dark p-a20'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div
                          className='bg-primary bg-bottom-right'
                          //   style={{ backgroundImage: 'url(' + img2 + ')' }}
                        >
                          <img src={img2} alt='' />
                          {/* <div className='text-black'>
                                <h4 className='mt-tilte m-t0'>Brochure</h4>
                                <p>
                                  Typefaces and layouts, and in appearance of
                                  different general the content of dorem ipsum
                                  dolor sit amet.{' '}
                                </p>
                                <NavLink
                                  to='/project-detail'
                                  className='site-button-secondry btn-half'
                                >
                                  <span> Download brochure</span>
                                </NavLink>
                              </div> */}
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='text-white p-r15'>
                          <h4 className='mt-tilte'>Let's help you!</h4>
                          <p>
                            There are many variations of passages of lorem
                            available, but the majority have suffered alteration
                            in some form, by inject humour, or randomized words
                            which don't look even slightly believable.
                          </p>
                          <NavLink
                            to='/contactus'
                            className='site-button btn-effect'
                          >
                            Contact Us
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-5 col-sm-6 m-b30'>
                  <div className='our-exp'>
                    <div className='mt-box'>
                      <h3 className='m-t0'>
                        <span className='font-weight-100'> We have</span>{' '}
                        <span className='text-primary'>29 years</span>{' '}
                        experience in engineering works
                      </h3>
                    </div>
                    <li>
                      <span className='progressText text-black'>
                        <b>Automotive Industries</b>
                      </span>
                    </li>
                    <br />
                    <li>
                      <span className='progressText text-black'>
                        <b>Automation Industries</b>
                      </span>
                    </li>
                    <br />
                    <li>
                      <span className='progressText text-black'>
                        <b>Bottling Industries</b>
                      </span>
                    </li>
                    <br />
                    <li>
                      <span className='progressText text-black'>
                        <b>Food Industries Machinery</b>
                      </span>
                    </li>
                    <br />
                    <li>
                      <span className='progressText text-black'>
                        <b>Construction Machinery</b>
                      </span>
                    </li>
                    <br />
                    <li>
                      <span className='progressText text-black'>
                        <b>Pharmaceuticals Machinery</b>
                      </span>
                    </li>
                    <br />
                    <li>
                      <span className='progressText text-black'>
                        <b>Hardware</b>
                      </span>
                    </li>
                    <br />
                    <li>
                      <span className='progressText text-black'>
                        <b>Packaging Industries Machinery</b>
                      </span>
                    </li>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OurValue3;
